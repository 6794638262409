import './App.css';
import ChatBot from './pages/Chatbot';

function App() {
  return (
    <ChatBot />
  );
}

export default App;
