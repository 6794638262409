import React, { useState, useRef, useEffect } from 'react';
import {Box, HStack, VStack, Input, Button, Text, Avatar, CircularProgress, useColorModeValue } from '@chakra-ui/react';
import '../ChatBot.css'; // Import custom CSS file for scrollbar styling

const ChatBot = () => {
  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef(null);

  const bgColor = useColorModeValue("gray.100", "gray.700");
  const textColor = useColorModeValue("black", "white");

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [messages]);

  const handleMessageSend = async () => {
    if (!inputText.trim()) return;

    // Add user message to chat
    setMessages(prevMessages => [...prevMessages, { text: inputText, sender: 'user' }]);
    setInputText('');
    setIsLoading(true);

    // Call API to get response
    try {
      const response = await fetch('https://testedapi.unicodashboard.com/sendMessage', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ message: inputText }),
      });
      const data = await response.json();
      
      // Add bot response to chat
      setMessages(prevMessages => [...prevMessages, { text: data.text.value, sender: 'bot' }]);
    } catch (error) {
      console.error('Error fetching response:', error);
      // Handle error here
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <VStack 
    fontSize={13}
    height="95vh" width="100%" p={4} spacing={4} borderRadius="md" bg={bgColor} color={textColor}>
      <VStack flexGrow={1} align="flex-start" width="100%" 
      overflowY="auto"
      className='scrollbar'
      px={10}
      >
        {messages.map((message, index) => (
          <Box key={index} 
          alignSelf={message.sender === 'user' ? 'flex-end' : 'flex-start'} 
          px={"2%"} 
          >
            {message.sender === 'user' ? (
              <Box  display={'flex'}   alignItems={'center'} my={5}>
                <Avatar src="user_icon.png" 
                width={40} height={40} 
                mr={4}
                bg={'grey'}
                borderRadius={50}
                />
                {message.text}
              </Box>
            ) : (
                <Box  display={'flex'}   alignItems={'center'} my={5}>
                <Avatar
                 src="bot_icon.png" 
                 
                 bg={'grey'}
                 borderRadius={50}
                 width={40} height={40} />
                 <Box ml={5}>
                  {message.text.split('\n').map((item, i) => <div key={i}>{item}</div>)}
                  </Box>
              </Box>
            )}
          </Box>
        ))}
        <div ref={messagesEndRef} />
      </VStack>
      <HStack width="95%" height={"10%"} mt={5}>
        <Input
          value={inputText}
          onChange={e => setInputText(e.target.value)}
          placeholder="Type your message..."
          bg={bgColor}
          color={textColor}
          _placeholder={{ color: textColor }}
          borderRadius="10px"
          border="1px solid gray"
          height={"100%"}
          flex="1"
        />
        {isLoading ? (
          <CircularProgress isIndeterminate color="green.500" />
        ) : (
          <Button onClick={handleMessageSend} bg={'darkblue'}
          color='white'
          p={15}
          border={'none'} borderRadius={10}>Send</Button>
        )}
      </HStack>
    </VStack>
  );
};

export default ChatBot;
